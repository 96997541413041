declare global {
  interface Window {
    signChatInitData?: any;
  }
}

export const normalizeLocale = (locale: string) => {
  if (!locale) return '';

  const formattedLocale = locale.trim().replace('_', '-');
  const parts = formattedLocale.split('-');
  return parts[0];
};

export const setZoomInitData = (
  user: { id: number; name: string; emailAddress: string; plan: string },
  language: string,
  sessionId: string | null,
  supportTier: string,
  chatHmac: string,
  pageLoadTs: string,
) => {
  if (user && !window.signChatInitData) {
    window.signChatInitData = {
      id: user?.id,
      name: user?.name,
      email: user?.emailAddress,
      plan: user?.plan,
      sessionId,
      language: normalizeLocale(language),
      supportTier,
      chatHmac,
      pageLoadTs,
    };
  }
};
